<template>
  <layout-default> 
  </layout-default>
</template>

<script>
import LayoutDefault from "../../components/organisms/LayoutDefault/LayoutDefault.vue";
export default {
  components: { LayoutDefault },
};
</script>

<style>

</style>
